import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";

const Home = () => {
  return (
    <div
      name="home"
      className="w-full h-screen component"
    >
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full z-30">
        <p className="font-medium">Hi, my name is</p>
        <h1 className="text-4xl lg:text-6xl font-bold z-10">Chelsea Koenig</h1>
        <h2 className="text-xl lg:text-6xl font-bold">
          Full Stack Software Engineer
        </h2>
        <p className="text-sm sm:text-base py-2 lg:py-4 max-w-[700px]">
          With a focus on frontend, I drive business growth through modern web
          technologies, responsive applications, and engaging
          cross-collaboration.
        </p>

        <div className="z-20">
          <button className="group border-2 px-6 py-3 my-2 sm:my-0 lg:my-2 flex items-center hover:shadow-md hover:shadow-black first-line:transition-transform rounded">
            <Link
              to="work"
              spy={true}
              smooth={true}
              duration={300}
              title="View Chelsea's Work"
              aria-label="View Chelsea's work"
            >
              <p className="flex items-center space-x-2">
                View Work
                <HiArrowNarrowRight
                  className="ms-2 group-hover:rotate-90 duration-300"
                  title="arrow pointing in direction of portfolio work samples"
                />
              </p>
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
