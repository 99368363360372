import React from "react";
import { DiGithubBadge, DiBootstrap } from "react-icons/di";
import {
  SiAdobecreativecloud,
  SiFigma,
  SiIntellijidea,
  SiJirasoftware,
  SiTailwindcss,
  SiVisualstudiocode,
} from "react-icons/si";

const Tools = () => {
  return (
    <div
      name="tools"
      className="w-full h-screen component sm:my-12"
    >
      {/* container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-3xl sm:text-2xl lg:text-4xl font-bold inline border-b-4 border-red-700">
            Tools
          </p>
          <p className="py-2 lg:py-4">
            Additional development & production tools I've used include:
          </p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-2 lg:gap-4 text-center lg:py-8">
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <DiGithubBadge
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>GitHub</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <SiJirasoftware
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Jira</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <SiIntellijidea
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>IntelliJ</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <SiVisualstudiocode
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>VS Code</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <SiAdobecreativecloud
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Adobe Creative Cloud</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <SiFigma
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Figma</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <DiBootstrap
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Bootstrap</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <SiTailwindcss
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Tailwind</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
