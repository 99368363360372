import React from "react";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const Contact = () => {
  return (
    <div
      name="contact"
      className="w-full h-screen component"
    >
      <div className="flex flex-col justify-center items-center w-full h-full my-20">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-3xl lg:text-4xl font-bold inline border-b-4 border-red-700">
              Contact
            </p>
          </div>
          <div></div>
        </div>

        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-3xl lg:text-4xl font-bold">
            <p>Let's get in touch.</p>
          </div>

          <div className="contact-links pt-3">
            <a
              href="mailto:hello@chelseakoenig.com"
              title="Email"
              aria-label="Email Chelsea"
              className="hover:text-rose-600 duration-300"
            >
              hello@chelseakoenig.com
            </a>
          </div>
        </div>

        {/* Social icons*/}
        <footer>
          <div className="sm:hidden absolute left-1">
            <ul className="mt-5">
              <li className="inline-block hover:scale-110 duration-300">
                <a
                  className=""
                  href="https://www.linkedin.com/in/cmkoenig/"
                  target="_blank"
                  rel="noreferrer"
                  title="LinkedIn Profile - Chelsea Koenig"
                  aria-label="LinkedIn Profile"
                >
                  <FaLinkedin
                    size={30}
                    alt="LinkedIn Icon"
                  />
                </a>
              </li>

              <li className="inline-block hover:scale-110 duration-300">
                <a
                  className=""
                  href="https://github.com/emceekoenig"
                  target="_blank"
                  rel="noreferrer"
                  title="GitHub Profile - Chelsea Koenig"
                  aria-label="GitHub Profile"
                >
                  <FaGithub
                    size={30}
                    alt="GitHub Icon"
                  />
                </a>
              </li>

              <li className="inline-block hover:scale-110 duration-300">
                <a
                  className=""
                  href="mailto:hello@chelseakoenig.com"
                  title="Email - Chelsea Koenig"
                  aria-label="Email Chelsea"
                >
                  <HiOutlineMail
                    size={30}
                    alt="Email icon"
                  />
                </a>
              </li>

              <li className="inline-block hover:scale-110 duration-300">
                <a
                  className=""
                  href="https://drive.google.com/file/d/1wGRDVhfpHLNXohIGRz50NOOX5lJ1KsqY/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                  title="Resume - Chelsea Koenig"
                  aria-label="View Chelsea's Resume"
                >
                  <BsFillPersonLinesFill
                    size={30}
                    alt="Resume Icon"
                  />
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Contact;
