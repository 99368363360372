import React from "react";
import AIhaikuGenerator from "../assets/app-AI-Haiku-Generator.png";
import DictionaryApp from "../assets/app-dictionary.png";
import WeatherApp from "../assets/app-weather.png";
import WorldClockApp from "../assets/app-world-clock.png";
import BusinessSiteA from "../assets/business-site-project-tx.png";
import BusinessSiteB from "../assets/business-site-project-sixthandmain.png";

const Work = () => {
  return (
    <div
      name="work"
      className="w-full sm:h-screen component"
    >
      <div className="max-w-[1000px] mx-auto pt-16 p-4 flex flex-col justify-center w-full h-full">
        <div className="sm:pb-2 lg:pb-6">
          <p className="text-3xl lg:text-4xl font-bold inline border-b-4 border-red-700">
            Work
          </p>
          <p className="py-2 lg:pt-4 sm:pb-0">
            Check out some of my recent work:
          </p>
        </div>

        {/* Container - Grid of Work Samples */}
        <div className="grid sm:grid-cols-3 gap-0 sm:gap-4">
          {/* Grid Item: AI haiku generator app */}
          <div
            style={{ backgroundImage: `url(${AIhaikuGenerator})` }}
            className="group container rounded-md flex justify-center items-center mx-auto content-div h-[250px] sm:h-[200px] lg:h-[300px] w-[250px] sm:w-[200px] lg:w-[300px]"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-80 duration-500 text-center">
              <p className="lg:text-2xl font-bold text-white tracking-wider">
                AI Haiku Generator
              </p>
              <p className="lg:text-xl font-bold text-white tracking-wider">
                Built with React
              </p>

              <div className="pt-8 text-center">
                <a
                  href="https://haiku-poem-generator.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demo Dictionary app"
                  aria-label="Demo Dictionary app"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Demo
                  </button>
                </a>

                <a
                  href="https://github.com/emceekoenig/haiku-generator"
                  target="_blank"
                  rel="noreferrer"
                  title="View Dictionary app code"
                  aria-label="View Dictionary app code"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="block sm:hidden text-center">
            AI Haiku Generator App
          </div>

          {/* Grid Item: dictionary app */}
          <div
            style={{ backgroundImage: `url(${DictionaryApp})` }}
            className="group container rounded-md flex justify-center items-center mx-auto content-div h-[250px] sm:h-[200px] lg:h-[300px] w-[250px] sm:w-[200px] lg:w-[300px]"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-80 duration-500 text-center">
              <p className="lg:text-2xl font-bold text-white tracking-wider">
                Dictionary App
              </p>
              <p className="lg:text-xl font-bold text-white tracking-wider">
                Built with React
              </p>

              <div className="pt-8 text-center">
                <a
                  href="https://blue-lexicon.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demo Dictionary app"
                  aria-label="Demo Dictionary app"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Demo
                  </button>
                </a>

                <a
                  href="https://github.com/emceekoenig/dictionary-react-app"
                  target="_blank"
                  rel="noreferrer"
                  title="View Dictionary app code"
                  aria-label="View Dictionary app code"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="block sm:hidden text-center">Dictionary App</div>

          {/* Grid item: weather app */}
          <div
            style={{ backgroundImage: `url(${WeatherApp})` }}
            className="group container rounded-md flex justify-center items-center mx-auto content-div h-[250px] sm:h-[200px] lg:h-[300px] w-[250px] sm:w-[200px] lg:w-[300px]"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-80 duration-500 text-center">
              <p className="lg:text-2xl font-bold text-white tracking-wider">
                Weather App
              </p>
              <p className="lg:text-xl font-bold text-white tracking-wider">
                Built with React
              </p>
              <div className="pt-8 text-center">
                <a
                  href="https://weather-app-react-today.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demo Weather app"
                  aria-label="Demo Weather app"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Demo
                  </button>
                </a>

                <a
                  href="https://github.com/emceekoenig/react-weather-app"
                  target="_blank"
                  rel="noreferrer"
                  title="View Weather app code"
                  aria-label="View Weather app code"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="block sm:hidden text-center pb-3">Weather App</div>

          {/* Grid Item - world clock app */}
          <div
            style={{ backgroundImage: `url(${WorldClockApp})` }}
            className="group container rounded-md flex justify-center items-center mx-auto content-div h-[250px] sm:h-[200px] lg:h-[300px] w-[250px] sm:w-[200px] lg:w-[300px]"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-80 duration-500 text-center">
              <p className="lg:text-2xl font-bold text-white tracking-wider">
                World Clock App
              </p>
              <p className="lg:text-xl font-bold text-white tracking-wider">
                Built with React
              </p>
              <div className="pt-8 text-center">
                <a
                  href="https://world-clock-today.netlify.app/"
                  target="_blank"
                  rel="noreferrer"
                  title="Demo World Clock app"
                  aria-label="Demo World Clock app"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Demo
                  </button>
                </a>

                <a
                  href="https://github.com/emceekoenig/world-clock"
                  target="_blank"
                  rel="noreferrer"
                  title="View World Clock code"
                  aria-label="View World Clock code"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="block sm:hidden text-center pb-8">
            World Clock App
          </div>

          {/* Grid Item - Business Site Sample A */}
          <div
            style={{ backgroundImage: `url(${BusinessSiteA})` }}
            className="group container rounded-md flex justify-center items-center mx-auto content-div h-[250px] sm:h-[200px] lg:h-[300px] w-[250px] sm:w-[200px] lg:w-[300px]"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-80 duration-500 text-center">
              <p className="lg:text-2xl font-bold text-white tracking-wider">
                Consumer Website
              </p>
              <p className="lg:text-xl font-bold text-white tracking-wider">
                Built with Next.JS
              </p>

              <div className="pt-8 text-center">
                <a
                  href="https://mytaxxpress.com"
                  target="_blank"
                  rel="noreferrer"
                  title="Demo Tax Xpress site"
                  aria-label="Demo Tax Xpress site"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Demo
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="block sm:hidden text-center pt-2 pb-8">
            Consumer Website
          </div>

          {/* Grid Item - Business Site Sample B */}
          <div
            style={{ backgroundImage: `url(${BusinessSiteB})` }}
            className="group container rounded-md flex justify-center items-center mx-auto content-div h-[250px] sm:h-[200px] lg:h-[300px] w-[250px] sm:w-[200px] lg:w-[300px]"
          >
            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-80 duration-500 text-center">
              <p className="lg:text-2xl font-bold text-white tracking-wider">
                Consumer Website
              </p>
              <p className="lg:text-xl font-bold text-white tracking-wider">
                Built with React
              </p>

              <div className="pt-8 text-center">
                <a
                  href="https://sixthandmaineventcenter.com"
                  target="_blank"
                  rel="noreferrer"
                  title="Demo Sixth & Main site"
                  aria-label="Demo Sixth & Main site"
                >
                  <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-slate-800 font-bold text-sm lg:text-lg hover:text-white hover:bg-red-700 duration-300">
                    Demo
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="block sm:hidden text-center pt-2 pb-8">
            Consumer Website
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
