import React, { useState } from "react";
import { FaBars, FaTimes, FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import Logo from "../assets/favicon-2.ico.png";
import { Link } from "react-scroll";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[40px] flex justify-between items-center px-4 py-6 sm:py-6 z-40 component">
      <Link
        to="home"
        spy={true}
        smooth={true}
        duration={300}
        title="Home"
        aria-label="Go to top of home page"
        href="/"
      >
        <div className="hover:cursor-pointer flex hover:scale-110 transition-transform">
          <img
            src={Logo}
            alt="Chelsea Koenig Logo"
            className="w-[15px] lg:w-[25px] h-[15px]lg:h-[25px]  my-auto"
          />
          <p className="items-center mx-1 lg:mx-2 text-sky-900 dark:text-sky-200 lg:text-2xl my-auto hidden md:block">
            ck_
          </p>
        </div>
      </Link>

      {/* menu */}
      <div>
        <ul className="hidden md:flex text-sm">
          <li>
            <Link
              to="home"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:underline hover:decoration-red-700"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="about"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:underline hover:decoration-red-700"
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="skills"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:underline hover:decoration-red-700"
            >
              Skills
            </Link>
          </li>
          <li>
            <Link
              to="tools"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:underline hover:decoration-red-700"
            >
              Tools
            </Link>
          </li>
          <li>
            <Link
              to="work"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:underline hover:decoration-red-700"
            >
              Work
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:underline hover:decoration-red-700"
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>

      {/* Hamburger menu */}
      <div
        onClick={handleClick}
        className="md:hidden z-10"
      >
        {!nav ? (
          <FaBars
            className="cursor-pointer"
            aria-label="Mobile menu bars"
            title="Open Menu"
          />
        ) : (
          <FaTimes
            className="cursor-pointer hover:opacity-60 scale-150"
            aria-label="X shaped close button"
            title="Close Menu"
          />
        )}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? "hidden"
            : "absolute md:hidden top-0 left-0 w-full h-screen component flex flex-col justify-center items-center"
        }
      >
        <li className="py-6 text-4xl">
          <Link
            to="home"
            spy={true}
            smooth={true}
            duration={500}
            onClick={handleClick}
            className="hover:underline hover:decoration-red-700 hover:border-b-2 hover:border-cyan-400"
          >
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            to="about"
            spy={true}
            smooth={true}
            duration={500}
            onClick={handleClick}
            className="hover:underline hover:decoration-red-700 hover:border-b-2 hover:border-cyan-400"
          >
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            to="skills"
            spy={true}
            smooth={true}
            duration={500}
            onClick={handleClick}
            className="hover:underline hover:decoration-red-700 hover:border-b-2 hover:border-cyan-400"
          >
            Skills
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            to="tools"
            spy={true}
            smooth={true}
            duration={500}
            onClick={handleClick}
            className="hover:underline hover:decoration-red-700 hover:border-b-2 hover:border-cyan-400"
          >
            Tools
          </Link>
        </li>
        <li className="py-6 text-4xl">
          {" "}
          <Link
            to="work"
            spy={true}
            smooth={true}
            duration={500}
            onClick={handleClick}
            className="hover:underline hover:decoration-red-700 hover:border-b-2 hover:border-cyan-400"
          >
            Work
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            to="contact"
            spy={true}
            smooth={true}
            duration={500}
            onClick={handleClick}
            className="hover:underline hover:decoration-red-700 hover:border-b-2 hover:border-cyan-400"
          >
            Contact
          </Link>
        </li>
      </ul>

      {/* Social icons*/}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-0 duration-300 bg-sky-800">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.linkedin.com/in/cmkoenig/"
              target="_blank"
              rel="noreferrer"
              title="LinkedIn Profile"
              aria-label="Go to LinkedIn profile"
            >
              LinkedIn{" "}
              <FaLinkedin
                size={30}
                title="LinkedIn icon"
              />
            </a>
          </li>

          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-0 duration-300 bg-yellow-500">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://github.com/emceekoenig"
              target="_blank"
              rel="noreferrer"
              title="GitHub Profile"
              aria-label="Go to GitHub profile"
            >
              GitHub{" "}
              <FaGithub
                size={30}
                title="GitHub icon"
              />
            </a>
          </li>

          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-0 duration-300 bg-teal-500">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="mailto:hello@chelseakoenig.com"
              title="Email"
              aria-label="Email Chelsea"
            >
              Email <HiOutlineMail size={30} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
