import React from "react";
import {
  DiHtml5,
  DiCss3,
  DiJavascript,
  DiReact,
  DiMongodb,
  DiNodejsSmall,
  DiPython,
  DiJava,
} from "react-icons/di";
import { SiExpress, SiDjango, SiSpring, SiTypescript } from "react-icons/si";

const Skills = () => {
  return (
    <div
      name="skills"
      className="w-full h-screen component"
    >
      {/* container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-3xl sm:text-2xl lg:text-4xl font-bold inline border-b-4 border-red-700">
            Skills
          </p>
          <p className="py-2 lg:py-4">
            Below are the languages, frameworks & tools I've worked with:
          </p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-2 lg:gap-4 text-center lg:py-8">
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <DiHtml5
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>HTML</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <DiCss3
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>CSS</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <DiJavascript
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>JavaScript</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <SiTypescript
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>TypeScript</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <DiMongodb
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>MongoDB</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <SiExpress
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Express.js</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <DiReact
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>React</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8">
            <DiNodejsSmall
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Node.js</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8 block sm:hidden lg:block">
            <DiPython
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Python</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8 block sm:hidden lg:block">
            <SiDjango
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Django</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8 block sm:hidden lg:block">
            <DiJava
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Java</p>
          </div>

          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500 my-auto py-1 sm:py-0 md:py-2 lg:py-8 block sm:hidden lg:block">
            <SiSpring
              size={60}
              className="block mx-auto scale-75 lg:scale-100 icon"
            />
            <p>Spring Boot</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
